<template>
  <div class="testdetail">
    <div class="w_d center">
      <div class="bread">
        <div class="bread_content">
          <span class="span_one">当前位置：</span>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
            <el-breadcrumb-item>我的试卷</el-breadcrumb-item>
            <el-breadcrumb-item>
              <span class="font_orange">试卷详情</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="bread_hengxian"></div>
      </div>
      <div class="left_content">
        <el-card class="box-card">
          <div class="show_all">
            <el-checkbox v-model="checked">显示全部答案和解析（提示：单击题目可显示答案和解析）</el-checkbox>
          </div>
          <div class="title">
            <p>{{ paper_data.paperName }}</p>
          </div>
          <div class="grade">
            <span>年级：{{ paper_data.gradeName }}</span>
            <span>类型：{{ paper_data.paperType }}</span>
            <span>试题数：{{ paper_data.number }}</span>
            <span>浏览数：{{ paper_data.watch_number }}</span>
            <span>更新日期：{{ alldata.create_time }}</span>
          </div>
          <el-card shadow="never" class="main_content" v-for="(item, index) in array_test" :key="index">
            <div @click="show(item)">
              <div class="type">
                <span>类型：{{ item.type }}</span>
                <span>年级：{{ item.grade }}</span>
                <span>难度：{{ item.diffcn }}</span>
                <span>组卷次数：{{ item.is_set_paper }}</span>
                <span>时间：{{ item.create_time }}</span>
              </div>

              <div class="content_title rich_text_div_img" v-html="item.title"></div>

              <div class="option" v-if="item.type == '选择题'">
                <span>A、</span><span v-html="item.option_a" class="span_space rich_text_div_img"></span>
                <span>B、</span><span v-html="item.option_b" class="span_space rich_text_div_img"></span><br />
                <span>C、</span><span v-html="item.option_c" class="span_space rich_text_div_img"></span>
                <span>D、</span><span v-html="item.option_d" class="span_space rich_text_div_img"></span><br />
                <span v-if="item.option_e && item.option_e != ''">E、</span><span v-html="item.option_e" class="span_space rich_text_div_img"
                  v-if="item.option_e && item.option_e != ''"></span>
              </div>
            </div>

            <div class="knowledge" v-if="item.showknowledge == '2'">
              <div class="knowledge_one">
                <span>【知识点】</span><span>{{ item.knowledge }}</span>
              </div>
              <div class="knowledge_one">
                <span>【答案】</span><span v-html="item.answer2" class="rich_text_div_img"></span>
              </div>
              <div class="knowledge_one">
                <span>【解析】</span><span v-html="item.parse" class="rich_text_div_img"></span>
              </div>
            </div>

            <div class="correction">
              <el-popover width="230" trigger="click" style="margin: 0 10px" @after-leave="correctionData = ''">
                <el-input type="textarea" :rows="3" placeholder="纠错原因..." v-model="correctionData">
                </el-input>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini" type="text" @click="correctionErr(item)">提交</el-button>
                </div>
                <div class="correction_one" slot="reference">
                  <i class="el-icon-edit"></i><span>纠错</span>
                </div>
              </el-popover>

              <div class="correction_one" @click="collection(item)">
                <i class="el-icon-star-off"></i><span>收藏</span>
              </div>
            </div>
          </el-card>
        </el-card>
      </div>
      <div class="right_area">
        <el-card class="right_content">
          <p>相关试卷</p>
          <div class="right_word">
            <span>暂无数据</span>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "paperdetail",
  data() {
    return {
      // 试卷id
      paper_id: "",
      // 试卷数据
      paper_data: {},
      // 试题数据
      array_test: [],
      // 总数据
      alldata: {},
      // 控制所有答案显示与隐藏
      checked: false,
      // 纠错数据
      correctionData: "",
    };
  },
  created() {
    this.init();
  },
  watch: {
    checked(val) {
      console.log("ll", val);
      if (val == true) {
        this.array_test.forEach((item) => {
          item.showknowledge = 2;
        });
      } else {
        this.array_test.forEach((item) => {
          item.showknowledge = 1;
        });
      }
    },
  },
  methods: {
    // 初始化函数
    init() {
      this.paper_id = this.$route.query.paper_id;
      this.get_info();
    },
    // 组卷详情接口
    get_info() {
      let obj = {
        params: {
          kejian_id: this.paper_id,
        },
      };
      this.$axios.get("papers/get_info", obj).then((res) => {
        this.alldata = res.data.data;
        this.paper_data = res.data.data.paper_data;
        let test = res.data.data.data;
        let arr = [];
        for (var i in test) {
          // arr.push(...test[i].details)
          // console.log("ww",test[i])
          test[i].forEach((item) => {
            // console.log("sss",item.details)
            arr.push(...item.details);
          });
        }
        // console.log("eee",arr)

        this.array_test = arr;
        this.array_test.forEach((item2) => {
          this.$set(item2, "showknowledge", 1);
        });
      });
    },
    // 显示答案函数
    show(item) {
      console.log("item");
      if (item.showknowledge == 1) {
        item.showknowledge = 2;
      } else {
        item.showknowledge = 1;
      }
    },
    // 提交纠错原因
    correctionErr(item) {
      let obj = {
        id: item.id,
        msg: this.correctionData,
      };
      this.$axios.post("question/do_error", obj).then((res) => {
        this.$message.success("提交成功");
        this.correctionData = "";
      });
    },
    // 取消收藏函数
    collection(item) {
      var obj = {
        question_id: item.id,
      };

      this.$axios.post("question/collection_cancel", obj).then((res) => {
        this.$message.success(res.data.data.data);
        // this.myCollection();
      });
    },
  },
};
</script>


<style lang="less" scoped>
.testdetail {
  display: flex;
  justify-content: center;
  .center {
    margin-bottom: 60px;
    .left_content {
      float: left;
      width: 1060px;
      //   border: 1px solid black;
      .box-card {
        .show_all {
          padding-bottom: 5px;
          border-bottom: 1px dashed#EBEBEB;
        }
        .title {
          display: flex;
          justify-content: center;
          margin-top: 40px;

          p {
            font-size: 28px;
            font-weight: 400;
            color: #2b2b2b;
          }
        }
        .grade {
          width: 100%;
          display: inline-flex;
          justify-content: center;
          margin: 20px 0;
          span {
            margin-right: 30px;
          }
        }
        .main_content {
          margin-bottom: 20px;
          .type {
            padding-bottom: 10px;
            border-bottom: 1px dashed#EBEBEB;
            margin-bottom: 20px;
            span {
              margin-right: 30px;
            }
          }
          .content_title {
            margin-bottom: 15px;
          }
          .option {
            margin-bottom: 10px;
            span {
              // margin-right: 50px;
              line-height: 30px;
            }
            .span_space {
              margin-right: 50px;
            }
          }
          .knowledge {
            border-top: 1px dashed#EBEBEB;
            padding-top: 10px;
            .knowledge_one {
              margin-bottom: 20px;
              span:nth-child(1) {
                color: #3489fe;
                margin-right: 20px;
              }
            }
          }
          .correction {
            box-sizing: border-box;
            width: 100%;
            height: 58px;
            background-color: #f2f2f2;
            color: #3489fe;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 100px;
            font-size: 18px;
            .correction_one {
              margin-right: 50px;
              cursor: pointer;
              i {
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .right_area {
      float: right;
      width: 300px;

      .right_content {
        height: 400px;
        p {
          border-left: 3px solid #3489fe;
          padding-left: 20px;
          font-weight: 600;
          color: #3489fe;
          margin-bottom: 20px;
        }
        .right_word {
          margin-left: 50px;
        }
      }
    }
  }
}
.bb {
  background-color: blue;
}
</style>
